<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.drivingOffersByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="drivingOffersHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="drivingOffers"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:item.total_price="{ item }">
              {{ item.currency.symbol + item.total_price }}
            </template>
            <template v-slot:item.paid="{ item }">
              <v-icon :color="item.paid ? 'green' : 'red'">{{ item.paid ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.drivingOffers') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-if="user.role_id !== 1" v-model="drivingOfferDialog" max-width="1300px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(FormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveDrivingOffer)">
                        <v-card-text>
                          <v-container>
                            <v-card
                              v-for="(item, index) in editedItem.driving_offer_items"
                              :key="index"
                              class="px-5 my-5">
                              <v-row>
                                <v-col cols="12" md="12" sm="12" class="d-flex align-center">
                                  <span> #{{ index + 1 }} </span>
                                  <v-spacer />
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="error"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="removeDrivingOfferItem(index)">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('tooltips.delete') }}</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="max:200"
                                    v-slot="{ errors }"
                                    :name="'title' + index"
                                    :custom-messages="{
                                      max: $t('customValidations.fieldLengthMax', {
                                        field: $t('names.title'),
                                        length: 200,
                                      }),
                                    }">
                                    <v-text-field
                                      v-model="item.title"
                                      clearable
                                      counter="200"
                                      :label="$t('drivingOffersPage.title')"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="required"
                                    v-slot="{ errors }"
                                    :name="'vehicle_class' + index"
                                    :custom-messages="{
                                      required: $t('customValidations.fieldRequired', {
                                        field: $t('names.vehicle_class'),
                                      }),
                                    }">
                                    <v-autocomplete
                                      v-model="item.vehicle_class_id"
                                      :items="vehicleClasses"
                                      clearable
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('drivingOffersPage.vehicleClass')"
                                      :error-messages="errors"
                                      :no-data-text="$t('select.noDataAvailable')"
                                      @focus="$event.target.click()"></v-autocomplete>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="required|numeric|max:10"
                                    v-slot="{ errors }"
                                    :name="'quantity' + index"
                                    :custom-messages="{
                                      required: $t('customValidations.fieldRequired', {
                                        field: $t('names.quantity'),
                                      }),
                                      max: $t('customValidations.fieldLengthMax', {
                                        field: $t('names.quantity'),
                                        length: 10,
                                      }),
                                      numeric: $t('customValidations.fieldType', {
                                        field: $t('names.quantity'),
                                        type: $t('fieldTypes.integer'),
                                      }),
                                    }">
                                    <v-text-field
                                      v-model="item.quantity"
                                      clearable
                                      counter="10"
                                      :label="$t('drivingOffersPage.quantity')"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="required|numeric_decimal|max:10"
                                    v-slot="{ errors }"
                                    :name="'price' + index"
                                    :custom-messages="{
                                      required: $t('customValidations.fieldRequired', {
                                        field: $t('names.price'),
                                      }),
                                      max: $t('customValidations.fieldLengthMax', {
                                        field: $t('names.price'),
                                        length: 10,
                                      }),
                                      numeric_decimal: $t('customValidations.fieldType', {
                                        field: $t('names.price'),
                                        type: $t('fieldTypes.integer'),
                                      }),
                                    }">
                                    <v-text-field
                                      v-model="item.price"
                                      clearable
                                      counter="10"
                                      :label="$t('drivingOffersPage.price')"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="numeric|max:3|max_value:100"
                                    v-slot="{ errors }"
                                    :name="'discount' + index"
                                    :custom-messages="{
                                      numeric: $t('customValidations.fieldType', {
                                        field: $t('names.discount'),
                                        type: $t('fieldTypes.integer'),
                                      }),
                                      max: $t('customValidations.fieldLengthMax', {
                                        field: $t('names.discount'),
                                        length: 3,
                                      }),
                                      max_value: $t('customValidations.fieldValueMax', {
                                        field: $t('names.discount'),
                                        max: 100,
                                      }),
                                    }">
                                    <v-text-field
                                      v-model="item.discount"
                                      clearable
                                      counter="3"
                                      :label="$t('drivingOffersPage.discount')"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="2" md="2" sm="6">
                                  <validation-provider
                                    rules="required|numeric|max:3|max_value:100"
                                    v-slot="{ errors }"
                                    :name="'vat' + index"
                                    :custom-messages="{
                                      required: $t('customValidations.fieldRequired', {
                                        field: $t('names.vat'),
                                      }),
                                      numeric: $t('customValidations.fieldType', {
                                        field: $t('names.vat'),
                                        type: $t('fieldTypes.integer'),
                                      }),
                                      max: $t('customValidations.fieldLengthMax', {
                                        field: $t('names.vat'),
                                        length: 3,
                                      }),
                                      max_value: $t('customValidations.fieldValueMax', {
                                        field: $t('names.vat'),
                                        max: 100,
                                      }),
                                    }">
                                    <v-text-field
                                      v-model="item.vat"
                                      clearable
                                      counter="3"
                                      :label="$t('drivingOffersPage.vat') + '(%)'"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>
                              </v-row>
                            </v-card>

                            <v-row>
                              <v-col cols="12" md="12" sm="12">
                                <v-spacer></v-spacer>
                                <v-btn color="primary cancel" text @click="addDrivingOfferItem">
                                  {{ $t('buttons.addItem') }}
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" lg="3" md="3" sm="12">
                                <validation-provider rules="" v-slot="{ errors }" name="currency">
                                  <v-autocomplete
                                    v-model="editedItem.currency_id"
                                    :items="currencies"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('transactionsPage.currency')"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>

                              <v-col cols="12" lg="12" md="12" sm="12">
                                <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                                  <v-textarea
                                    v-model="editedItem.description"
                                    counter="1000"
                                    :label="$t('drivingOffersPage.description')"
                                    rows="1"
                                    :error-messages="errors"></v-textarea>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="drivingOfferDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedItem.number }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('drivingOffersPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="!enableSave" class="primary" text @click="deleteItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="downloadDrivingOffer(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-download</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on">
                            {{ $t('buttons.downloadFile') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>
                        {{ $t('tooltips.downloadFile') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <div style="display: block" v-bind="attrs" v-on="on">
                          <v-list-item :disabled="disableGeneratePaymentLink" @click="generatePaymentLink(item.id)">
                            <v-icon class="mx-1" small v-bind="attrs">mdi-link-variant</v-icon>
                            <v-list-item-title>
                              {{ $t('buttons.generatePaymentLink') }}
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </template>
                      <span v-if="disableGeneratePaymentLink">
                        {{ $t('tooltips.cannotGeneratePaymentLInk') }}
                      </span>
                      <span v-else>
                        {{ $t('tooltips.generatePaymentLink') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { drivingOffersHeaders } from '@/mixins/data-table-headers';
import { defaultDrivingOffer, defaultDrivingOfferItem } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDateTime } from '@/utils/formatDate';

export default {
  name: 'DrivingOffersPage',
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    drivingOfferDialog: false,
    drivingOfferDialogDelete: false,
    drivingOffers: [],
    FormTitle: 'drivingOffersPage.newItem',
    editedItem: {},
    enableSave: true,
    loading: false,
    vehicleClasses: [],
    currencies: [],
    user: {},
  }),
  created() {
    this.user = this.$store.getters['auth/user'];
    this.editedItem = Object.assign(
      {},
      { ...defaultDrivingOffer, driving_offer_items: [Object.assign({}, defaultDrivingOfferItem)] }
    );
    this.loadAllDrivingOffers();
    this.loadAllVehicleClasses();
    this.loadAllCurrencies();
  },
  computed: {
    drivingOffersHeaders() {
      return drivingOffersHeaders(i18n);
    },
    disableGeneratePaymentLink() {
      return !this.user?.organisation?.organisation_settings?.stripe_connect_enabled;
    },
  },
  methods: {
    async loadAllDrivingOffers() {
      await this.$store.dispatch('drivingOffers/getAllDrivingOffers').then((res) => {
        this.drivingOffers = res.data.map((e) => {
          e.active = parseInt(e.active);
          e.default = parseInt(e.default);
          e.created_at = formatDateTime(e.created_at);
          return e;
        });
      });
    },

    deleteItem(item) {
      this.editedItem = this.drivingOffers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.drivingOfferDialogDelete = true;
    },

    async saveDrivingOffer() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveDrivingOffer';

      await this.$store
        .dispatch('drivingOffers/' + path, this.editedItem)
        .then((res) => {
          this.loadAllDrivingOffers();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeEdit();
          this.downloadDrivingOffer(res.data);
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.FormTitle = 'drivingOffersPage.newItem';
        });
    },

    async deleteItemConfirm() {
      await this.$store
        .dispatch('drivingOffers/deleteDrivingOffer', this.editedItem)
        .then((res) => {
          if (res.success) {
            this.loadAllDrivingOffers();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeDelete();
        });
    },

    closeEdit() {
      this.drivingOfferDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          {},
          { ...defaultDrivingOffer, driving_offer_items: [Object.assign({}, defaultDrivingOfferItem)] }
        );
      });
      this.$refs.form.reset();
      this.FormTitle = 'drivingOffersPage.newItem';
    },

    closeDelete() {
      this.drivingOfferDialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          {},
          { ...defaultDrivingOffer, driving_offer_items: [Object.assign({}, defaultDrivingOfferItem)] }
        );
      });
    },

    downloadDrivingOffer(item) {
      window.open(item.file_path, '_blank');
    },

    generatePaymentLink(id) {
      this.$store
        .dispatch('drivingOffers/generateDrivingOfferPaymentLink', { driving_offer_id: id })
        .then((res) => {
          window.open(res.data, '_blank');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },

    async loadAllVehicleClasses() {
      let path = 'getAllVehicleClasses';

      await this.$store.dispatch('vehicleClasses/' + path).then((res) => {
        this.vehicleClasses = res.data.map((item) => {
          if (item.active == '1') {
            return item;
          }
        });
      });
    },

    addDrivingOfferItem() {
      this.editedItem.driving_offer_items.push({ ...this.defaultDrivingOfferItem });
    },
    removeDrivingOfferItem(index) {
      this.editedItem.driving_offer_items.splice(index, 1);
    },
    async loadAllCurrencies() {
      await this.$store.dispatch('currencies/getOrganisationCurrencies').then((res) => {
        this.currencies = res.data.map((item) => {
          if (item.currency.active == '1') {
            return item.currency;
          }
        });
      });
    },
  },

  watch: {
    drivingOfferDialog(val) {
      val || this.closeEdit();
    },
  },
};
</script>
